'use client'

import { Button, EmptyState, Image } from '@vinted/web-ui'

import useAsset from '../../hooks/useAsset'

type Props = {
  body: string
  title: string
  action?: string
  actionUrl?: string
  onActionClick?: () => void
}

const ErrorContent = ({ body, title, action, actionUrl, onActionClick }: Props) => {
  const asset = useAsset('/assets/error-page')

  const renderAction = () => {
    if (!action) return null
    if (!actionUrl && !onActionClick) return null

    return (
      <Button styling={Button.Styling.Filled} url={actionUrl} onClick={onActionClick}>
        {action}
      </Button>
    )
  }

  return (
    <EmptyState
      image={<Image src={asset('404-rack.svg')} />}
      title={title}
      body={body}
      action={renderAction()}
    />
  )
}
export default ErrorContent
